<template>
  <div class="button_menu mt-3">
    <div class="btn-group w-100" role="group" aria-label="Basic example">
      <router-link
        to="/"
        type="button"
        class="btn btn-primary"
        :class="{ active: $route.path === '/' }"
        exact
        >Tracking</router-link
      >
      <router-link
        to="/goservice"
        type="button"
        class="btn btn-primary"
        :class="{ active: $route.path === '/goservice' }"
        exact
        >Go Service</router-link
      >
      <router-link
        to="/etiket"
        type="button"
        class="btn btn-primary"
        :class="{ active: $route.path === '/etiket' }"
        exact
        >E-Tiket</router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
