<template>
  <div id="app">
    <div class="instal_app">
      <div
        v-if="deferredPrompt !== null"
        class="alert alert-warning alert-dismissible fade show sticky-top"
        style="margin-bottom: -15px; border-radius: 0"
        role="alert"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center gap-2">
            <img
              src="../public/img/icons/apple-touch-icon-60x60.png"
              alt=""
              style="width: 40px"
            />
            <div>
              <p style="font-size: 12px">Install Aplikasi</p>
              <p style="font-size: 14px; margin-top: -5px">SFI Solutions</p>
            </div>
          </div>
          <div>
            <button
              id="installApp"
              @click="installPWA"
              class="btn btn-primary btn-sm"
            >
              Install App
            </button>
          </div>
        </div>

        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </div>
    <div class="contain">
      <router-view />
      <loadins-screen-component />
    </div>
  </div>
</template>

<script>
import LoadinsScreenComponent from "./components/LoadinsScreenComponent.vue";
export default {
  name: "HomeView",
  components: { LoadinsScreenComponent },
  data() {
    return {
      refreshing: false,
      deferredPrompt: null,
    };
  },
  mounted() {
    window.addEventListener(
      "beforeinstallprompt",
      this.handleBeforeInstallPrompt
    );
    window.addEventListener("beforeunload", () => {
      this.refreshing = true;
    });
    // this.$root.$on("ajax-start", () => {
    //   this.loading = true;
    // });
    // this.$root.$on("ajax-stop", () => {
    //   this.loading = false;
    // });
  },
  beforeDestroy() {
    window.removeEventListener(
      "beforeinstallprompt",
      this.handleBeforeInstallPrompt
    );
  },

  methods: {
    handleBeforeInstallPrompt(event) {
      event.preventDefault();
      this.deferredPrompt = event;
    },
    async installPWA() {
      if (this.deferredPrompt !== null) {
        this.deferredPrompt.prompt();
        const { outcome } = await this.deferredPrompt.userChoice;
        if (outcome === "accepted") {
          this.deferredPrompt = null;
        }
      }
    },
  },
};
</script>

<style>
.instal_app .alert-warning {
  --bs-alert-padding-y: 0.5rem;
  --bs-alert-padding-x: 0.5rem;
}
</style>