<template>
  <div class="img_banner">
    <img class="img-bg" src="../assets/img/banner.png" alt="" />
    <img class="img-logo" src="../assets/img/LOGO SFI_1.png" alt="" />
    <p v-if="sosmed">
      <font-awesome-icon
        :icon="['fab', 'whatsapp']"
        size="lg"
        class="me-2"
      />+62{{ sosmed.whatsapp }}
    </p>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "BannerComponent",
  data() {
    return {
      sosmed: null,
    };
  },
  async mounted() {
    try {
      const response = await api.get("Content/sosmed");
      this.sosmed = response.data.data[0];
    } catch (error) {
      // console.error(error);
    }
  },
};
</script>
  
  <style></style>
  