import axios from "axios";
import Vue from "vue";
const api = axios.create({
  baseURL: "https://admin.sfisolutions.id/api/",
  // baseURL: "http://localhost/gadget/gadgetcare/api",
  headers: {
    "SFI-KEY": "36428",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  function (config) {
    Vue.prototype.$bus.$emit("loading", true);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    Vue.prototype.$bus.$emit("loading", false);
    return response;
  },
  function (error) {
    Vue.prototype.$bus.$emit("loading", false);
    return Promise.reject(error);
  }
);

export default api;
